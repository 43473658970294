import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: "/",
    routes:[
        {
            path: '/',
            name: 'inicio',
            component: () => import("@/components/Inicio"),
            meta: {
                guest: true
            }
        },
        {
            path: '/politicas',
            name: 'politicas',
            component: () => import("@/components/politicas"),
            meta: {
                guest: true
            }
        },
        {
            path: '/terminos',
            name: 'terminos',
            component: () => import("@/components/terminos"),
            meta: {
                guest: true
            }
        },
        {
            path: '/evento',
            name: 'evento',
            component: () => import("@/components/evento"),
            meta: {
                guest: true
            }
        },
        {
            path: '/ordenes',
            name: 'ordenes',
            component: () => import("@/components/ordenes"),
            meta: {
                guest: true
            }
        },
        {
            path: '/entrada',
            name: 'entrada',
            component: () => import("@/components/entrada"),
            meta: {
                guest: true
            }
        },
        {
            path: '/perfil',
            name: 'perfil',
            component: () => import("@/components/perfil"),
            meta: {
                guest: true
            }
        }
    ]

});


export default router