<template>
  <div id="app">
    <main class="py-4" >
      <router-view></router-view>

    </main>
  </div>
</template>

<script>
import {  mapActions } from 'vuex';

export default {
  name: 'App',

  components: {

  },

  data: () => ({
    //
  }),
  created() {
    this.$vuetify.theme.dark = false
  },
  mounted: function () {
    this.resolveResolution();
  },
  methods:{
    ...mapActions([
      'updateResolution',
    ]),
    resolveResolution(){
      const size = window.innerWidth;
      this.isMobile = size <= 768;
      this.updateResolution(this.isMobile);
    }
  }
};
</script>
