import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isMobile: false,
        routes: null,
        routesObject: null,
        showSpinner: false,
        showInscribete: false,
        showDatosPersonales: false,
        showDatosUbicacion:false,
        showChangePassword:false,
        showTransferencia:false,
        user: {
            loggedIn: false,
            data: null
        },
        evento:{},
        sexos:[],
        sexo:{},
        cliente: {},
        paises: [],
        pais:{},
        tipopagos:[],
        tipoentradas:[],
        tipoeventos:[],
        tipopago:{},
        tipoentrada:{},
        tipoevento:{},
        dias:[],
        dia:{},
        usuario:{},
        ticket:{}
    },
    getters:{
        user(state){
            return state.user
        },
        showInscribete(state){
            return state.showInscribete
        },
        showChangePassword(state){
            return state.showChangePassword
        },
        showDatosPersonales(state){
            return state.showDatosPersonales
        },
        showDatosUbicacion(state){
            return state.showDatosUbicacion
        },
        showTransferencia(state){
            return state.showTransferencia
        },
        usuario(state){
            return state.usuario
        },
        cliente(state){
            return state.cliente
        },
        ticket(state){
            return state.ticket
        },
        clientes(state){
            return state.clientes
        },
        pais(state){
            return state.pais
        },
        dia(state){
            return state.dia
        },
        banco(state){
            return state.banco
        },
        dias(state){
            return state.dias
        },
        bancos(state){
            return state.bancos
        },
        evento(state){
            return state.evento
        },
        paises(state){
            return state.paises
        },
        tipopagos(state){
            return state.tipopagos
        },
        tipoeventos(state){
            return state.tipoeventos
        },
        tipoentradas(state){
            return state.tipoentradas
        },
        tipoentrada(state){
            return state.tipoentrada
        },
        tipopago(state){
            return state.tipopago
        },
        tipoevento(state){
            return state.tipoevento
        },
        sexos(state){
            return state.sexos
        },
        sexo(state){
            return state.sexo
        }
    },
    mutations: {
        SET_LOGGED_IN(state, value) {
            state.user.loggedIn = value;
        },
        SET_USER(state, data) {
            state.user.data = data;
        },
        showPreloader(state) {
            state.showSpinner = true;
        },
        hidePreloader(state) {
            state.showSpinner = false;
        },
        showInscripcion(state) {
            state.showInscribete = true;
        },
        hideInscripcion(state) {
            state.showInscribete = false;
        },
        showChangePassword(state) {
            state.showChangePassword = true;
        },
        hideChangePassword(state) {
            state.showChangePassword = false;
        },
        showTransferencia(state) {
            state.showTransferencia = true;
        },
        hideTransferencia(state) {
            state.showTransferencia = false;
        },
        showDatosPersonales(state) {
            state.showDatosPersonales = true;
        },
        hideDatosPersonales(state) {
            state.showDatosPersonales = false;
        },
        showDatosUbicacion(state) {
            state.showDatosUbicacion = true;
        },
        hideDatosUbicacion(state) {
            state.showDatosUbicacion = false;
        },
        updateResolution(state, payload) {
            state.isMobile = payload;
        },
        SET_TIPOENTRADAS(state, data) {
            for( var item in data){
                state.tipoentradas.push(data[item]);
            }
        },
        SET_TIPOEVENTOS(state, data) {
            for( var item in data){
                state.tipoeventos.push(data[item]);
            }
        },
        SET_TIPOPAGOS(state, data) {
            for( var item in data){

                state.tipopagos.push(data[item]);
            }
        },
        SET_SEXOS(state, data) {
            for( var item in data){
                state.sexos.push(data[item]);
            }
        },
        SET_CLIENTE(state, data) {
            state.cliente = data
        },
        SET_TICKET(state, data) {
            state.ticket = data
        },
        SET_EVENTO(state, data) {
            state.evento = data
        },
        SET_CLIENTES(state, data) {
            for( var item in data){
                state.clientes.push(data[item].cliente);
            }
        },
        SET_BANCOS(state, data) {
            for( var item in data){
                state.bancos.push(data[item]);
            }
        },
        SET_DIAS(state, data) {
            for( var item in data){
                state.dias.push(data[item]);
            }
        },
        SET_PAISES(state, data) {
            for( var item in data){
                state.paises.push(data[item]);
            }
        },
        SET_USUARIO(state, data){
            state.usuario = data
        }
    },
    actions: {
        showPreloader: ({commit}) => commit('showPreloader'),
        hidePreloader: ({commit}) => commit('hidePreloader'),
        showInscripcion: ({commit}) => commit('showInscripcion'),
        hideInscripcion: ({commit}) => commit('hideInscripcion'),
        showDatosUbicacion: ({commit}) => commit('showDatosUbicacion'),
        hideDatosUbicacion: ({commit}) => commit('hideDatosUbicacion'),
        showDatosPersonales: ({commit}) => commit('showDatosPersonales'),
        hideDatosPersonales: ({commit}) => commit('hideDatosPersonales'),
        showChangePassword: ({commit}) => commit('showChangePassword'),
        hideChangePassword: ({commit}) => commit('hideChangePassword'),
        showTransferencia: ({commit}) => commit('showTransferencia'),
        hideTransferencia: ({commit}) => commit('hideTransferencia'),
        updateResolution: ({commit}, payload) => commit('updateResolution', payload),
        fetchUser({ commit }, user) {
            commit("SET_LOGGED_IN", user !== null);
            if (user) {
                commit("SET_USER", {
                    displayName: user.displayName,
                    email: user.email
                });
            } else {
                commit("SET_USER", null);
            }
        },
        fetchClientes({ commit }, clientes) {
            commit("SET_CLIENTES", clientes);
        },
        fetchTipoEntradas({ commit }, tipoentradas) {
            commit("SET_TIPOENTRADAS", tipoentradas);
        },
        fetchTipoEventos({ commit }, tipoeventos) {
            commit("SET_TIPOEVENTOS", tipoeventos);
        },
        fetchTipoPagos({ commit }, tipopagos) {
            commit("SET_TIPOPAGOS", tipopagos);
        },
        fetchBancos({ commit }, bancos) {
            commit("SET_BANCOS", bancos);
        },
        fetchDias({ commit }, dias) {
            commit("SET_DIAS", dias);
        },
        fetchSexos({ commit }, sexos) {
            commit("SET_SEXOS", sexos);
        },
        fetchEvento({ commit }, evento) {
            commit("SET_EVENTO", evento);
        },
        fetchTicket({ commit }, evento) {
            commit("SET_TICKET", evento);
        },
        fetchCliente({ commit }, cliente) {
            commit("SET_CLIENTE", cliente);
        },
        fetchUsuario({commit},usuario){
            commit("SET_USUARIO", usuario);
        }
    }
});