import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from 'axios';
import * as firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/analytics';
import router from "./routes/index";
import store from "./store";
import VueSweetalert2 from 'vue-sweetalert2';

window.axios = axios;
axios.defaults.baseURL = process.env.VUE_APP_API;
Vue.config.productionTip = false


import 'sweetalert2/dist/sweetalert2.min.css';

const configOptions = {
  apiKey: "AIzaSyBTROuJ3i0eEeETK0_kQN4HQp7PHkGudu4",
  authDomain: "eventos-do-76766.firebaseapp.com",
  databaseURL: "https://eventos-do-76766.firebaseio.com",
  projectId: "eventos-do-76766",
  storageBucket: "eventos-do-76766.appspot.com",
  messagingSenderId: "275689703844",
  appId: "1:275689703844:web:1314ca3de7386946",
  measurementId: "G-HMHE82L7V3"
};

firebase.initializeApp(configOptions);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
firebase.analytics();
const optionssw = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};
Vue.use(VueSweetalert2,optionssw);


new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
